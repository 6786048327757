import { computed } from 'vue';

export const uuid = computed<string>(() => {
  const randomHex = () => Math.floor(Math.random() * 16).toString(16);

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = randomHex();
    if (char === 'x') {
      return random;
    } else {
      const randomY = (parseInt(random, 16) & 0x3) | 0x8;
      return randomY.toString(16);
    }
  });

  return uuid;
});
